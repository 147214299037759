import React from 'react'
import { Link } from 'theme-ui'
import SVG from 'react-inlinesvg'
import { HeaderLogo } from '@elegantstack/flow-ui-layout/src/Header/Header.Logo'

const styles = {
  link: {
    display: `inline-flex`,
    color: `heading`,
    fontWeight: `medium`,
    ':visited': {
      color: `heading`
    },
    svg: {
      fill: `omegaDarker`,
      height: 24,
      ml: 2
    }
  }
}

const PoweredByGatsby = () => (
  <Link
    variant='mute'
    target='_blank'
    title='Formtory'
    href='https://formtory.com'
    rel='noopener'
    sx={styles.link}
  >
    Powered by Formtory
  </Link>
)

export default PoweredByGatsby