import { useBlogCategories } from '@helpers-blog'
import pageContextProvider from '@helpers/pageContextProvider'
import Categories from '@widgets/Categories'
import Search from '@widgets/Search'
import React, { useContext } from 'react'
import { Box, Container, Flex } from 'theme-ui'
import { HeaderColorMode } from './Header.ColorMode'
import { HeaderLogo } from './Header.Logo'
import Sticky from '@components/Sticky'


const styles = {
  wrapper: {
    position: `relative`,
    bg: `headerBg`,
  },
  container: {
    position: `relative`,
    zIndex: 10
  },
  logoContainer: {
    flexBasis: [`full`, null, `2/4`],
    order: [2, null, `unset`],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logoBlogContainer: {
    height: '12rem',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  searchContainer: {
    flexBasis: [`auto`, null, `1/4`],
    minWidth: `auto`,
    order: [1, null, `unset`],
    // mx: 3,
  },
  colorModeContainer: {
    flexBasis: [`auto`, null, `1/4`],
    minWidth: `auto`,
    order: [3, null, `unset`],
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  categoriesContainer: {
    display: [`none`, `block`],
    borderBottom: '1px solid',
    borderColor: 'omegaLighter'
  },
  logo: {
    a: {
      textDecoration: 'none',
      color: `heading`,
    },
    fontFamily: `heading`,
    fontSize: ['12px', '16px', '16px'],
    lineHeight: '24px',
    fontWeight: 500,
    textAlign: 'center',
    color: `heading`,
    '&:not(:last-child) > ::after': {
      content: `" . "`,
      position: `relative`,
      top: -1,
      pr: [1, 2, 3],
      pl: [1, 1, 2],
    }
  },
  logoBlog: {
    a: {
      textDecoration: 'none',
      color: `heading`,
    },
    fontFamily: `heading`,
    fontSize: ['40px', '60px', '80px'],
    lineHeight: '80px',
    fontWeight: 600,
    textAlign: 'center',
    color: `heading`,
  },
}

export const Header = ({ children }) => {
  const context = useContext(pageContextProvider)

  const { services, darkMode } = context.pageContext

  const algolia = services && services.algolia
  const categories = useBlogCategories()

  return (
    <Box full sx={styles.wrapper}>
      <Container variant='wide' sx={styles.container}>
        {/* <Box sx={{ width: '100%', display: ['none', 'block'] }}>
          <Banner slot="9401402906" height="200px" format='horizontal' />
        </Box> */}
        <Flex variant='layout.header'>
          <Box sx={styles.searchContainer}>{!algolia && <Search />}</Box>
          <Box sx={styles.logoContainer}>
            {/* <HeaderLogo /> */}
            <Box sx={styles.logo}><a href="https://formtory.com">Formtory.com</a></Box>
            <Box sx={styles.logo}><a href="https://formtory.com/vi/chuc-nang">Chức Năng</a></Box>
            <Box sx={styles.logo}><a href="https://formtory.com/vi/template">Mẫu</a></Box>
            <Box sx={styles.logo}><a href="https://admin.formtory.com">Đăng Ký</a></Box>
          </Box>
          <Box sx={styles.colorModeContainer}>
            {darkMode && <HeaderColorMode />}
          </Box>
        </Flex>
        <Box sx={styles.logoBlogContainer}>
          <HeaderLogo />
          <Box sx={styles.logoBlog}><a href="/">Formtory Blog</a></Box>
        </Box>
        <Box sx={styles.categoriesContainer}>
          <Categories
            categories={categories}
            variant='horizontal'
            omitTitle
          />
        </Box>
      </Container>
      {children}
    </Box>
  )
}